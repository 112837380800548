import { Card, CardContent, Typography } from '@/core/ui';
import { cn } from '@/lib';
import { useTranslation } from 'react-i18next';

interface ITeam {
  Position: number;
  CountryName: string;
  FifaCountryCode: string;
  Matches: number;
  Victories: number;
  Draws: number;
  Losses: number;
  GoalsScored: number;
  GoalsConceded: number;
  Points: number;
  PhaseGroupName: string;
}

interface IMatch {
  MatchId: number;
  MatchDate: string;
  HomeTeam: string;
  AwayTeam: string;
  HomeScore: number | null;
  AwayScore: number | null;
  HomeFifaCountryCode: string;
  AwayFifaCountryCode: string;
  PhaseGroupName: string;
}

interface IExtraInfo {
  group_stage_teams: ITeam[];
  matches: {
    group_stage: IMatch[];
    round_of_16: IMatch | null;
    quarter_finals: IMatch | null;
    semi_finals: IMatch | null;
    final: IMatch | null;
  };
}

interface Section2Props {
  extraInfo: IExtraInfo;
}

interface ChartProps {
  groupStageTeams: ITeam[];
}

interface GroupProps {
  match: IMatch;
}

interface EliminationsProps {
  matches: IMatch[];
}

const transformMatches = (matches: IExtraInfo['matches']): IMatch[] => {
  return [
    matches.round_of_16 && { ...matches.round_of_16, PhaseGroupName: 'OITAVOS DE FINAL' },
    matches.quarter_finals && { ...matches.quarter_finals, PhaseGroupName: 'QUARTOS DE FINAL' },
    matches.semi_finals && { ...matches.semi_finals, PhaseGroupName: 'MEIAS-FINAIS' },
    matches.final && { ...matches.final, PhaseGroupName: 'FINAL' },
  ].filter(Boolean) as IMatch[];
};

const Group: React.FC<GroupProps> = ({ match }) => (
  <div className='flex flex-col w-[375px] h-[311px] space-y-4'>
    <Typography className='text-2xl leading-[30px] tracking-[1.2px]'>
      {new Date(match.MatchDate).toLocaleDateString('pt-PT')}
    </Typography>
    <Typography className='text-2xl leading-[30px] tracking-[1.2px]'>
      {new Date(match.MatchDate).toLocaleTimeString('pt-PT', {
        hour: '2-digit',
        minute: '2-digit',
      })}
    </Typography>
    <div className='flex mt-4 space-x-3 items-center'>
      <Typography className='text-3xl leading-[34.5px] font-bold'>{match.HomeScore}</Typography>
      <img
        src={`https://fpf360cdnprd.blob.core.windows.net/country/${match.HomeFifaCountryCode}.png`}
        className='w-6 h-6'
        alt={match.HomeTeam}
      />
      <Typography
        className={`text-2xl leading-[27.6px] tracking-[1.2px] uppercase truncate max-w-[100px] ${
          match.HomeTeam === 'PORTUGAL' ? 'font-bold' : ''
        }`}
      >
        {match.HomeTeam}
      </Typography>
    </div>
    <div className='flex mt-4 space-x-3 items-center'>
      <Typography className='text-3xl leading-[34.5px] font-bold'>{match.AwayScore}</Typography>
      <img
        src={`https://fpf360cdnprd.blob.core.windows.net/country/${match.AwayFifaCountryCode}.png`}
        className='w-6 h-6'
        alt={match.AwayTeam}
      />
      <Typography
        className={`text-2xl leading-[27.6px] tracking-[1.2px] uppercase truncate max-w-[100px] ${
          match.AwayTeam === 'PORTUGAL' ? 'font-bold' : ''
        }`}
      >
        {match.AwayTeam}
      </Typography>
    </div>
  </div>
);

const Chart: React.FC<ChartProps> = ({ groupStageTeams }) => {
  const { t } = useTranslation('translation');

  return (
  <div className='flex flex-col mt-14 space-y-4'>
    <div className='flex justify-between'>
      <Typography className='uppercase'>{groupStageTeams[0]?.PhaseGroupName} · {t('shirt.classification')}</Typography>
      <div className='grid grid-cols-7 gap-4 text-center'>
        {['P', 'J', 'V', 'E', 'D', 'GM', 'GS'].map((item, index) => (
          <Typography key={index}>{item}</Typography>
        ))}
      </div>
    </div>
    {groupStageTeams.map((team, index) => (
      <div
        key={`${team.CountryName}-${team.Position}`}
        className={cn('border-content3', { 'border-b': index !== groupStageTeams.length - 1 })}
      >
        <div className='flex items-center'>
          <div className='flex space-x-4 w-1/3'>
            <Typography className='text-2xl leading-[27.6px] tracking-[1.2px]'>{team.Position}</Typography>
            <img
              src={`https://fpf360cdnprd.blob.core.windows.net/country/${team.FifaCountryCode}.png`}
              className='w-6 h-6'
              alt={team.CountryName}
            />
            <Typography
              className={`text-2xl leading-[27.6px] tracking-[1.2px] uppercase ${
                team.CountryName === 'Portugal' ? 'font-bold' : ''
              }`}
            >
              {team.CountryName}
            </Typography>
          </div>
          <div className='grid grid-cols-7 gap-4 text-center w-2/3 pl-14'>
            {[
              team.Points,
              team.Matches,
              team.Victories,
              team.Draws,
              team.Losses,
              team.GoalsScored,
              team.GoalsConceded,
            ].map((stat, statIndex) => (
              <Typography key={statIndex}>{stat}</Typography>
            ))}
          </div>
        </div>
      </div>
    ))}
  </div>
  )
};

const Eliminations: React.FC<EliminationsProps> = ({ matches }) => {
  const { t } = useTranslation('translation');

  return (
  <>
    <Typography className='text-[34px] flex justify-center leading-[34px] tracking-[1.7px] font-semibold uppercase'>
      {t('shirt.qualifiers')}
    </Typography>
    <div className='overflow-x-auto w-full py-6'>
      <div className='flex justify-center items-center space-x-8 min-w-max'>
        {matches.map((match) => (
          <div
            key={match.MatchId}
            className='flex flex-col justify-center bg-chalk rounded-[50px] px-14 py-10 shadow-board w-[375px] h-[311px]'
          >
            <Typography className='text-2xl leading-[27.6px] tracking-[1.2px] font-bold'>
              {match.PhaseGroupName}
            </Typography>
            <Group key={`${match.MatchId}`} match={match} />
          </div>
        ))}
      </div>
    </div>
  </>
  );
};

export const Section2: React.FC<Section2Props> = ({ extraInfo }) => {
  const { t } = useTranslation('translation');
  const transformedMatches = transformMatches(extraInfo.matches);

  return (
    <Card className='w-full shadow-board rounded-[40px] p-24'>
      <CardContent className='w-full h-full flex flex-col'>
          <div className='flex flex-row justify-between space-x-16 px-12'>
            <div className='flex-1'>
              <Typography className='text-[34px] leading-[34px] tracking-[1.7px] font-semibold'>
                {t('shirt.final_stage')}
              </Typography>
              <Chart groupStageTeams={extraInfo.group_stage_teams} />
            </div>
            <div className='flex-1'>
              <Typography className='text-[34px] leading-[34px] tracking-[1.7px] font-semibold'>
                {t('shirt.groups')}
              </Typography>
              <div className='grid grid-cols-3 gap-8 mt-14'>
                {extraInfo.matches.group_stage.map((match) => (
                  <Group key={`${match.MatchId}-${match.MatchDate}`} match={match} />
                ))}
              </div>
            </div>
          </div>
          <div className='w-full bg-match bg-bottom -mb-20 bg-no-repeat h-[30rem]'>
            <Eliminations
              matches={Object.values(transformedMatches)
                .flat()
                .filter((match): match is IMatch => match !== null)
                .map((match, index) => ({ ...match, uniqueKey: `${match.MatchId}-${index}` }))}
            />
          </div>
      </CardContent>
    </Card>
  );
};
