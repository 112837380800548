export const GLOBE = ({ color, ...props }: React.ComponentPropsWithRef<'svg'>) => (
  <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 18C0 8.05889 8.05889 0 18 0C27.9412 0 36.0001 8.05889 36.0001 18C36.0001 27.9412 27.9412 36.0001 18 36.0001C8.05889 36.0001 0 27.9412 0 18ZM18 3.27273C9.86637 3.27273 3.27273 9.86637 3.27273 18C3.27273 26.1337 9.86637 32.7273 18 32.7273C26.1337 32.7273 32.7273 26.1337 32.7273 18C32.7273 9.86637 26.1337 3.27273 18 3.27273Z'
      fill={color ?? 'white'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 17.9996C0 17.0959 0.732626 16.3633 1.63637 16.3633H34.3637C35.2674 16.3633 36.0001 17.0959 36.0001 17.9996C36.0001 18.9034 35.2674 19.636 34.3637 19.636H1.63637C0.732626 19.636 0 18.9034 0 17.9996Z'
      fill={color ?? 'white'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.5918 0.110857C18.8355 0.205393 19.047 0.354106 19.2147 0.540048C23.5712 5.31289 26.0468 11.5051 26.1814 17.966C26.1819 17.9887 26.1819 18.0115 26.1814 18.0342C26.0468 24.4983 23.5687 30.6935 19.2081 35.4674C18.8981 35.8068 18.4596 36.0001 17.9999 36.0001C17.5403 36.0001 17.1018 35.8068 16.7917 35.4674C12.4312 30.6935 9.95314 24.4983 9.81847 18.0342C9.818 18.0115 9.818 17.9887 9.81847 17.966C9.95314 11.5018 12.4312 5.30665 16.7917 0.532828C17.2472 0.0341959 17.9622 -0.133414 18.5918 0.110857ZM13.0912 18.0001C13.2034 23.0317 14.9338 27.8758 17.9999 31.8261C21.0661 27.8758 22.7965 23.0317 22.9087 18.0001C22.7965 12.9685 21.0661 8.12437 17.9999 4.1741C14.9338 8.12437 13.2034 12.9685 13.0912 18.0001Z'
      fill={color ?? 'white'}
    />
  </svg>
);
