import { LANGUAGES } from '@/config';
import { Button, Typography } from '@/core/ui';
import { cn } from '@/lib';
import { useTranslation } from 'react-i18next';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation('translation');

  const changeLanguage = (lng: LANGUAGES) => {
  i18n.changeLanguage(lng);
};

  return (
    <div className='flex flex-row space-x-8 h-full'>
      <div className='flex flex-row space-x-6'>
        {Object.entries([LANGUAGES.ENGLISH, LANGUAGES.PORTUGUESE]).map(([, lng]) => (
          <Button
            type='button'
            onClick={() => changeLanguage(lng)}
            key={lng}
            className={cn(
              'h-[108px] w-[108px] flex items-center justify-center',
              'bg-chalk text-center rounded-full border border-content2 focus:bg-chalk active:bg-chalk',
              { 'border-danger': lng === i18n.language },
            )}
          >
            <Typography
              className={cn('text-[37px] text-content2 pt-0.5 font-semibold uppercase', {
                'text-danger': lng === i18n.language,
              })}
            >
              {lng}
            </Typography>
          </Button>
        ))}
      </div>
    </div>
  );
};
