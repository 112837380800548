import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF, Html, useProgress } from '@react-three/drei';
import { Suspense, useRef, useEffect } from 'react';
import { type Object3D, Box3, Vector3 } from 'three';

interface ModelProps {
  path: string;
  rotation: number;
}

const Model: React.FC<ModelProps> = ({ path, rotation }) => {
  const { scene } = useGLTF(path);
  const modelRef = useRef<Object3D>(null);

  useEffect(() => {
    if (modelRef.current) {
      const box = new Box3().setFromObject(modelRef.current);
      const size = box.getSize(new Vector3());
      const center = box.getCenter(new Vector3());

      const maxAxis = Math.max(size.x, size.y, size.z);
      const scale = 5.8 / maxAxis;
      modelRef.current.scale.set(scale, scale, scale);

      modelRef.current.position.set(-center.x * scale, -center.y * scale, -center.z * scale);
    }
  }, [scene]);

  useEffect(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y = rotation;
    }
  }, [rotation]);

  return <primitive object={scene} ref={modelRef} />;
};

const Loader: React.FC = () => {
  const { progress } = useProgress();
  return (
    <Html center>
      <div style={{ textAlign: 'center', color: 'white' }}>
        <div
          style={{
            width: '120px',
            height: '120px',
            border: '10px solid #f3f3f3',
            borderTop: '10px solid #3498db',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite',
          }}
        />
        <p style={{ marginTop: '16px', fontSize: '18px' }}>{progress.toFixed(0)}% Loaded</p>
      </div>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </Html>
  );
};

const ModelViewer: React.FC<ModelProps> = ({ path, rotation }) => {
  const encodedPath = encodeURI(path);

  return (
    <div style={{ width: '90%', maxWidth: '90vw', height: '90%', maxHeight: '90vh', margin: '0 auto' }}>
      <Canvas camera={{ position: [0, 1, 5] }}>
        <ambientLight intensity={2} />
        <directionalLight position={[2, 2, 2]} intensity={1.5} />
        <spotLight position={[-2, 5, 5]} angle={0.3} intensity={1.2} penumbra={1} />
        <spotLight position={[2, 5, 5]} angle={0.3} intensity={1.2} penumbra={1} />
        <pointLight position={[0, 3, 0]} intensity={0.8} />
        <Suspense fallback={<Loader />}>
          <Model path={encodedPath} rotation={rotation} />
        </Suspense>
        <OrbitControls
          enableZoom={true}
          autoRotate
          autoRotateSpeed={1.5}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
        />
      </Canvas>
    </div>
  );
};

export default ModelViewer;
