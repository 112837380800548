import { Button, Typography } from '@/core/ui';
import { useTranslation } from 'react-i18next';
interface IQuizContentProps {
  data: IQuizContentData;
  onAnswerClick: (selectedOption: string) => void;
}

export const QuizContent = ({ data, onAnswerClick }: IQuizContentProps) => {
  const { t } = useTranslation('translation');
  const { imageSrc, question, answers, currentLevel, currentQuestion } = data;

  return (
    <div className='mt-11 flex flex-col w-full items-center pb-12 space-y-8 bg-white rounded-[50px] shadow-md'>
      {imageSrc && (
        <img src={imageSrc} alt='Quiz' className='rounded-t-[50px] w-full h-[1000px] object-cover' />
      )}
      <div className='flex flex-col justify-center text-center mt-20'>
        <Typography className='uppercase text-neutral-600 text-3xl'>
          {`${t('quiz.play.content.level')} ${currentLevel} - ${t(
            'quiz.play.content.question',
          )} ${currentQuestion}`}
        </Typography>
        <Typography className='w-[1500px] text-7xl font-semibold text-neutral-800 mt-14'>{question}</Typography>
      </div>

      <div className='flex flex-col w-full mt-20 space-y-9 px-[88px]'>
        {Object.entries(answers).map(([key, text]) => (
          <Button
            key={key}
            className='w-full h-[124px] py-4 border bg-white border-black rounded-[20px] hover:bg-gray-100 transition duration-200'
            onClick={() => onAnswerClick(key)}
          >
            <Typography className='text-[55px] text-gray-800'>{text}</Typography>
          </Button>
        ))}
      </div>
    </div>
  );
};
