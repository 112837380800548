import { HtmlMarkup, LazyImage } from '@/core/ui';

interface ICompetition {
  squad_description: string;
  squad_image: {
    path: string;
    caption: string;
  };
}

export const Section5 = ({ competition }: { competition: ICompetition }) => {
  return (
    <div className=''>
      <LazyImage
        inView
        src={competition.squad_image.path}
        alt={competition.squad_image.caption}
        className='overflow-hidden h-[1002px] rounded-[40px]'
      />
      <div className='mt-6'>
        <HtmlMarkup className='text-[26px] leading-[29.9px] tracking-[1.3px]'>
          {competition.squad_description}
        </HtmlMarkup>
      </div>
    </div>
  );
};
